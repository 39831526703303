$varColorCoal: #2b3036;
$varColorAlto: #dadada;
$varColorSand: #f5f5f5;
$varColorPink: #f841c1;
$varColorSpace: #3a4149;
$varColorPale: #627081;

*, :before, :after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline-color: var(--pink);
  transform-origin: center center;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
  margin: 0;
  font-size: 100.01%;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -ms-content-zooming: none;
  -ms-overflow-style: scrollbar;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  background: $varColorSand;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

*:not(svg):not(path) {
  color: $varColorCoal;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  //font-weight: 500;
  line-height: 1.4285714286;
}

a {
  color: #000;
}

.main-bg {
  background: url(../images/theme-signup.png) center 0 no-repeat $varColorCoal;
  background-size: cover;
  background-attachment: fixed;
}

// FORM


input, select, textarea {
  position: relative;
  z-index: 1;
  margin: 0;
  box-shadow: none;
  font-size: 1em;
  line-height: normal;
  text-align: left;
  text-transform: none;
  outline-width: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  display: block;
  width: 100%;
  border-radius: 16px;
  border: 1px solid $varColorAlto;
  background: #f5f5f5;
  background: $varColorSand;

  height: 40px;
  padding: 0 1rem;
}

textarea {
  height: auto;
  padding: 1rem;
}

input[type=checkbox], input[type=radio] {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0;
  border: 0;
  background: none;
  line-height: 1;
}

// MAIN BTN
.main-btn {
  background: $varColorPink;
  padding: 12px 32px;

  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  min-width: 106px;
  border-width: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 16px;
}

.cancel-btn {
  background: #FFF;
  padding: 12px 32px;

  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  min-width: 106px;
  border-width: 2px;
  border-color: #000;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 16px;
}

.fill-btn {
  background: $varColorPale;
  padding: 12px 32px;

  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  min-width: 106px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  border-radius: 16px;
}

// LAYOUT
.bg-coal {
  background: $varColorCoal;
}
.data-container {
  width: calc(100vw - 20rem);
}
.data-container-subscribe {
  width: 600px;
}
// SVG
.text-white svg {
  color: #FFF !important;
}

// MENU
.capibox-menu {
  & a {
    &.active {
      background: $varColorSpace;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
    }
  }
}

// TABLE
table {
  //display: table;
  position: relative;
  //z-index: 2;
  width: 100%;
  //border-collapse: collapse;
  //border-spacing: 0;

  &:before {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    background: #fff;
  }

  & thead {
    & tr:first-child {
      & *:first-child:before {
        border-top-left-radius: 16px;
      }

      & *:last-child:before {
        border-top-right-radius: 16px;
      }
    }
  }


  & th {
    position: relative;

    &:before {
      background: $varColorCoal;
    }

    padding-top: 14px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 700;
    text-align: left;
  }

  & th, & td {
    padding: 14px 24px;
  }
}

// HACK
table:before, th:before {
  content: "";
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  text-align: left;
  text-indent: -3000em;
}

// USERGUIDE
.user-guide iframe {
  width: 100%;
  height: calc(100vh - 150px);
}

// CHECKBOx
.input-switch {
  position: relative;
}
.input-switch label:after, .input-switch label:before, .popup-a .close, .tooltip .inner {
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-delay: 0s;
}

.input-switch label:before {
  z-index: 1;
  width: 20px;
  top: 5px;
  height: 10px;
  background: #e0e0e0;
}

.input-switch label:after, .input-switch label:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  border-radius: 10px;
}

.input-switch label:after {
  z-index: 2;
  width: 16px;
  top: 2px;
  height: 16px;
  background: #9e9e9e;
  cursor: pointer;
}

.input-switch label:after, .input-switch label:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  border-radius: 10px;
}

.input-switch input:checked~label:after {
  left: 4px;
  background: #f841c1;
}
.input-switch input:checked~label:before {
  background: #fca4e2;
}

// TOAST

.Toastify__close-button {
  margin-right: 0 !important;
  min-width: 15px;
}

.Toastify__close-button::before {
  border: none;
  border-radius: 0;
  background: transparent;
}

.Toastify__toast {
  border-radius: 10px !important;
}

.Toastify__toast-body {
  color: #FFF;
}
